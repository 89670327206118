// import React from 'react'
// import { shallow } from 'enzyme'

// import RemarkRenderer from '../index'

describe('<RemarkRenderer />', () => {
  it('Expect to have unit tests specified', () => {
    expect(true).toEqual(false)
  })
})
